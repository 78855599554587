import './App.scss';
import ehData from './data.json';
import lhData from './lisbeth.json';
import shuffle from 'lodash-es/shuffle'
import * as React from 'react';
import { toRomaji, toKana } from 'wanakana';

function registerVisibilityAPIListener (listener)
{
	var hidden, visibilityChange;
	if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
		hidden = "hidden";
		visibilityChange = "visibilitychange";
	} else if (typeof document.msHidden !== "undefined") {
		hidden = "msHidden";
		visibilityChange = "msvisibilitychange";
	} else if (typeof document.webkitHidden !== "undefined") {
		hidden = "webkitHidden";
		visibilityChange = "webkitvisibilitychange";
	}

	// Warn if the browser doesn't support addEventListener or the Page Visibility API
	if (typeof document.addEventListener === "undefined" || hidden === undefined) {
        return;
	} else {
        document.addEventListener(visibilityChange, () => {listener(document[hidden])}, false);
	}
}

function KanaKanjiCount (word)
{
    return word.replace(/[a-zA-Z0-9\s]/g,'').length;
}

function toggleFullscreen (self)
{
    if (!document.fullscreenElement)
    {
        document.documentElement.requestFullscreen().then( () => {
            self.forceUpdate();
        });
    }
    else
    {
        document.exitFullscreen().then( () => {
            self.forceUpdate();
        });
    }
}

class dataSet
{
    constructor ()
    {
        this.tags = localStorage.getItem("tags");
        this.data = [];
        this.prevData = [];
        this.dataSet = localStorage.getItem('dataset');
        this.entryNo = -1;
        if(this.dataSet !== "lh" && this.dataSet !== "eh")
        {
            this.dataSet = 'eh';
        }
        if(this.tags === undefined || this.tags === null || this.tags === "" || this.tags.length === 0)
        {
            this.tags = "frå_japansk";
        }
    }

    setTags(tags)
    {
        localStorage.setItem('tags',tags);
        this.tags = tags;
        this._reShuffleData();
    }

    getTagList()
    {
        var entries = {};
        var data = this._getDataSet();
        for (const category of data)
        {
            var tags = category.tags.split(/(\s+|,\s*)/);
            for(var tag of tags)
            {
                if(tag !== "" && tag !== null && tag !== undefined && tag !== " ")
                {
                    entries[tag] = true;
                }
            }
        }
        return Object.keys(entries).sort();
    }

    toggleDataSet()
    {
        if(this.dataSet === "lh")
        {
            this.dataSet = "eh";
        }
        else
        {
            this.dataSet = "lh";
        }
        localStorage.setItem('dataset',this.dataSet);
        this._reShuffleData();
    }

    setDataSet(dataset)
    {
        this._dataSet = dataset;
        this._reShuffleData();
    }

    _parseTags(tagsRaw)
    {
        var result = [];
        var tags = tagsRaw.split(/(\s+|,\s*)/);
        for (var tag of tags)
        {
            var str = tag;
            var type = 'include';
            if(tag.substr(0,1) === "+")
            {
                str = str.substr(1);
            }
            else if(tag.substr(0,1) === "-")
            {
                str = str.substr(1);
                type = 'exclude';
            }
            var entry = {
                str,
                type
            };
            result.push(entry);
        }
        return result;
    }

    _entryMatchTags(entry,tags)
    {
        var match = true;
        for (var tag of tags)
        {
            if(tag.type === "include")
            {
                if (entry.tags.indexOf(tag.str) === -1)
                {
                    match = false;
                }
            }
            else if(tag.type === "exclude")
            {
                if (entry.tags.indexOf(tag.str) !== -1)
                {
                    match = false;
                }
            }
        }
        return match;
    }

    _getDataSet ()
    {
        var data;
        if(this.dataSet === 'eh')
        {
            data = ehData;
        }
        else
        {
            data = lhData;
        }
        return data;
    }

    _reShuffleData ()
    {
        var included = {};
        var words = [];
        var data = this._getDataSet();
        this.entryNo = -1;
        var tagList = this._parseTags(this.tags);

        for (const category of data)
        {
            if(this._entryMatchTags(category,tagList))
            {
                if(category.words === undefined)
                {
                    console.log('INVALID CATEGORY (SKIPPING): '+JSON.stringify(category));
                    continue;
                }
                for (const word of Object.keys(category.words))
                {
                    if(! included[word])
                    {
                        words.push({
                            definition: category.words[word][0],
                            word,
                        });
                        included[word] = true;
                    }
                }
            }
        }
        if (words.length === 0)
        {
            this.tags = "frå_japansk";
            return this._reShuffleData();
        }
        this.data = shuffle(words);
    }

    previous ()
    {
        var prev = this.entryNo-1;
        if(this.data[prev] !== undefined)
        {
            this.entryNo = prev;
        }
        else
        {
            this.entryNo = 0;
        }
        return this.data[this.entryNo];
    }

    next ()
    {
        var next = this.entryNo+1;
        if(this.data[next] === undefined)
        {
            next = 0;
            this._reShuffleData();
        }
        this.entryNo = next;
        return this.data[next];
    }

    getTimerFor(entry)
    {
        var longestWord;
        var stringEntry = entry.word+'';
        var stringDefinition = entry.definition+'';
        if(stringEntry.length > stringDefinition.length)
        {
            longestWord = stringEntry;
        }
        else
        {
            longestWord = stringDefinition;
        }
        // Wait 1s extra per kana or kanji
        var extraWaitKanaKanji = KanaKanjiCount(entry.word)*1000;
        var wait = ((longestWord.length/2)*1000)+3000+extraWaitKanaKanji;
        return wait;
    }
}

class Controls extends React.Component
{
    updateTags (data)
    {
        var result = prompt("Skriv inn taggar. Skriv - foran taggen for å ikkje inkludere den taggen.\n\nTaggar: "+data.getTagList().join(', '),data.tags);
        if(result !== null)
        {
            data.setTags(result);
        }
    }

    render ()
    {
        const isMobile = matchMedia('(max-width:500px)').matches;
        var { loopRunning, toggleLoop, refresh, data } = this.props;
        var current = data.dataSet;
        var goFullscreen = '⛶';
        if (document.fullscreenElement)
        {
            goFullscreen = '⧉';
        }
        var currentDataset;
        if(current === 'eh')
        {
            currentDataset = 'え';
        }
        else
        {
            currentDataset = 'へ';
        }
        var loopControl = '▶';
        if (loopRunning)
        {
            loopControl = '| |';
        }
        var fullscreenToggler = [
            <span key="fullscreenToggler" onClick={ (e) => {
                e.stopPropagation();
                toggleFullscreen(this);
            }}>{goFullscreen}</span>
        ];
        if (!isMobile)
        {
            fullscreenToggler = [];
        }
        var previousClass = "inactive";
        if(data.entryNo > 0)
        {
            previousClass = "";
        }
        return <div className="controls">
            <span onClick={ (e) => {
                e.stopPropagation();
                data.toggleDataSet(); refresh();
            } }>{currentDataset}</span>
            <span onClick={ (e) => {
                e.stopPropagation();
                toggleLoop();
            } }>{loopControl}</span>
            <span onClick={ (e) => {
                e.stopPropagation();
                this.updateTags(data);
            }}>⌘</span>
            <span className={previousClass} onClick={ (e) => {
                e.stopPropagation();
                refresh("previous");
            }}>←</span>
            <span onClick={ (e) => {
                e.stopPropagation();
                refresh();
            }}>→</span>
            {fullscreenToggler}
        </div>;
    }
}

class PronounciationHelper extends React.Component
{
    uEndPronounciation (arr,key)
    {
        if(arr[arr.length-1] === "u" && arr[arr.length-2] === "s")
        {
            arr[arr.length-1] = <i key={"u"+key}>u</i>;
            return true;
        }
        return false;
    }

    render ()
    {
        var { romaji } = this.props;
        var pronounced = [];

        var romajiEntries = romaji.split('');
        var prev = "\0";
        var key = 0;
        for(var no in romajiEntries)
        {
            var character = romajiEntries[no];
            if(character === "o")
            {
                key++;
                character = "å";
            }
            if(character === prev)
            {
                key++;
                pronounced.pop();
                pronounced.push(<u key={key}>
                    {character}{character}
                </u>);
            }
            else if(character === " ")
            {
                if(this.uEndPronounciation(pronounced,key))
                {
                    key++;
                }
                pronounced.push(character);
            }
            else
            {
                pronounced.push(character);
            }
            prev = character;
        }
        if(this.uEndPronounciation(pronounced,key))
        {
            key++;
        }
        if (key === 0)
        {
            return <div />;
        }
        return <div>
            Uttale: {pronounced}
        </div>;
    }
}
class DisplayIfDifferent extends React.Component
{
    render ()
    {
        var {prefix,word,original} = this.props;
        if(word === original)
        {
            return <span />;
        }
        return <div>
            {prefix}: {word}
        </div>;
    }
}

class KanaKanjiHelper extends React.Component
{
    constructor ()
    {
        super();
        this.state = {
            visible: false
        };
    }

    render ()
    {
        var { word } = this.props;
        var romaji = toRomaji(word);
        var kana = toKana(romaji);
        return <div onClick={ (e) => {
            e.stopPropagation();
            this.setState({visible: !this.state.visible});
        }}>
            <h1>{word}</h1>
            <div className="kana-romaji-helper" style={{display: this.state.visible ? "block" : "none"}}>
                <DisplayIfDifferent word={kana} prefix="Kana" original={word} />
                <DisplayIfDifferent word={romaji} prefix="Romaji" original={word} />
                <PronounciationHelper romaji={romaji} />
            </div>
        </div>
    }
}

class MainPage extends React.Component {
    constructor ()
    {
        super();
        this._data = new dataSet();
        this.state = {
            entry: this._data.next()
        };

        this._timerID = null;
        this._lock = false;
        this.loopRunning = false;
    }

    installArrowKeyHandler()
    {
        document.onkeydown = (e) => {
            const no = e.which;
            // Arrow right or enter
            if(no === 39 || no === 13)
            {
                this.refresh();
                e.preventDefault();
            }
            // Spacebar
            else if(no === 32)
            {
                this.toggleLoop();
                e.preventDefault();
            }
            // Arrow left
            else if(no === 37)
            {
                this.refresh("previous");
                e.preventDefault();
            }
            // F
            else if (no === 70)
            {
                toggleFullscreen(this);
                e.preventDefault();
            }
        };
    }

	componentDidMount() {
        this.queueRefresh();
        this.installArrowKeyHandler();
        const self = this;
        setTimeout(() => {
            registerVisibilityAPIListener(function (hidden) {
                if (!self.loopRunning)
                {
                    // Ignore
                    return;
                }
                if (hidden) {
                    self.clearRefresh();
                } else {
                    self.queueRefresh();
                }
            });
        },1000);
	}

	componentWillUnmount() {
		clearInterval(this._timerID);
	}

    toggleLoop ()
    {
        this.clearRefresh();
        if(this.loopRunning)
        {
            this.loopRunning = false;
        }
        else
        {
            this.loopRunning = true;
            this.refresh();
        }
        this.forceUpdate();
    }

    clearRefresh ()
    {
        if(this._timerID !== null)
        {
            clearInterval(this._timerID);
        }
    }

    queueRefresh(entry)
    {
        if (!this.loopRunning)
        {
            return;
        }
        if(entry === undefined)
        {
            entry = this.state.entry;
        }
        this.clearRefresh();
        this._timerID = setTimeout( () => {
            this.refresh();
        },this._data.getTimerFor(entry));
    }

    refresh (method)
    {
        let entry;
        if(method === "previous")
        {
            entry = this._data.previous();
        }
        else
        {
            entry = this._data.next();
        }
        this.clearRefresh();
        this.setState({
            entry,
        });
        if(this.loopRunning)
        {
            this.queueRefresh(entry);
        }
    }

    render ()
    {
        const entry = this.state.entry;
        return <div className="wrapper" onClick={() => { this.refresh(); }}>
            <main>
                <div className="card">
                    <KanaKanjiHelper key={entry.word} word={entry.word} />
                    <h2>{entry.definition}</h2>
                </div>
                <Controls data={this._data} refresh={ (mode) => { this.refresh(mode); }} toggleLoop={() => { this.toggleLoop(); }} loopRunning={this.loopRunning} />
            </main>
        </div>;
    }
}

export default MainPage;
